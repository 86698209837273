import moment from './moment/moment'
const durationFormat = (time, formatType) => {
    let millisecond = +time * 1000
    return moment.utc(millisecond).format(formatType || 'mm:ss')   // formatType: HH:mm:ss, mm:ss
}

// 驼峰变量名转中划线
const toCenterLine = (str) => {
    let nstr = str.replace(/[A-Z]/g,function($0){//函数里只有一个参数时表示与 regexp 中的表达式相匹配的文本。有多个参数时表示与 regexp 中的子表达式相匹配的文本
        // console.log($0);//Y C  个人的理解就是第一个参数会返回正则表达式//里匹配到的所有文本
        return "-"+$0.toLocaleLowerCase();
    });
    //防止有开头大驼峰
    if(nstr.slice(0,1) == "-"){
        nstr = nstr.slice(1)
    }
    return nstr;
}

const completeTimestampTime = (time) => {
    let date = new Date(time)
    let year = date.getFullYear() + '-' //获取当前年份
    let mon = date.getMonth() + 1  + '-' //获取当前月份
    let da = date.getDate() + ' ' //获取当前日
    let h = date.getHours() + ':' //获取小时
    let m = date.getMinutes() + ':' //获取分钟
    let s = date.getSeconds() + ':' //获取秒
    let hs = date.getMilliseconds() //获取毫秒

    return year + mon + da + h + m + s + hs
}

/**
 * 数字转成汉字
 * @params num === 要转换的数字
 * @return 汉字
 * */
const toChinesNum = (num) => {
    var arr1 = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']
    var arr2 = ['', '十', '百', '千', '万', '十', '百', '千', '亿', '十', '百', '千', '万', '十', '百', '千', '亿']
    if (!num || isNaN(num)) return '零'
    var english = num.toString().split('')
    var result = ''
    for (var i = 0; i < english.length; i++) {
        var des_i = english.length - 1 - i // 倒序排列设值
        result = arr2[i] + result
        var arr1_index = english[des_i]
        result = arr1[arr1_index] + result
    }
    result = result.replace(/零(千|百|十)/g, '零').replace(/十零/g, '十') // 将【零千、零百】换成【零】 【十零】换成【十】
    result = result.replace(/零+/g, '零') // 合并中间多个零为一个零
    result = result.replace(/零亿/g, '亿').replace(/零万/g, '万') // 将【零亿】换成【亿】【零万】换成【万】
    result = result.replace(/亿万/g, '亿') // 将【亿万】换成【亿】
    result = result.replace(/零+$/, '') // 移除末尾的零
    // 将【一十】换成【十】
    result = result.replace(/^一十/g, '十')
    return result
}

export default {
    durationFormat,
    toCenterLine,
    completeTimestampTime,
    toChinesNum
}
